<!-- =========================================================================================
  File Name: DataListListView.vue
  Description: Data List - List View
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="data-list-list-view" class="data-list-container">
    <feather-icon
      icon="ArrowLeftIcon"
      svgClasses="h-8"
      style="cursor: pointer"
      @click="$route.params.ID > 0 ? $router.push('/Doctor') : $router.push('/Patient')"
    />

    <vx-card
      ref="filterCard"
      v-bind:title="$t('Filters')"
      class="user-list-filters mb-1"
      collapse-action
    >
      <div class="vx-row">
        <!-- DateFrom -->
        <div class="vx-col md:w-1/5 sm:w-1/2 w-full mb-2" v-if="SearchObj != null">
          <label class="text-sm opacity-75">{{ $t("Date From") }}</label>
          <datepicker
            placeholder="from"
            v-model="SearchObj.DateFrom"
            class="vx-col w-full"
            name="from"
          ></datepicker>
        </div>
        <!-- DateTo -->
        <div class="vx-col md:w-1/5 sm:w-1/2 w-full mb-2" v-if="SearchObj != null">
          <label class="text-sm opacity-75">{{ $t("Date To") }}</label>
          <datepicker
            placeholder="from"
            v-model="SearchObj.DateTo"
            class="w-full"
            name="to"
          ></datepicker>
        </div>

        <!-- BookingTypeID -->
        <div class="vx-col md:w-1/5 sm:w-1/2 w-full mb-2" v-if="SearchObj != null&&!$acl.check('hospitalgroup')">
          <label class="text-sm opacity-75">{{ $t("Booking type") }}</label>
          <v-select
            label="label"
            class="w-full"
            v-model="SearchObj.BookingTypeID"
            :options="BookingTypeList"
            :reduce="(ID) => ID.value"
            @input="
              SearchObj.DoctorID = null;
              SearchObj.HospitalID = null;
            "
          />
        </div>
        <!-- DoctorID -->
        <div
          v-if="(SearchObj.BookingTypeID == 1 || SearchObj.BookingTypeID == 3)&& !$acl.check('hospitalgroup')"
          class="vx-col md:w-1/5 sm:w-1/2 w-full mb-2"
        >
          <label class="text-sm opacity-75">{{ $t("doctors") }}</label>
          <v-select
            label="Name"
            class="w-full"
            v-model="SearchObj.DoctorID"
            :options="doctors"
            :reduce="(ID) => ID.ID"
          />
        </div>
        <!-- PatientID -->
        <div class="vx-col md:w-1/5 sm:w-1/2 w-full mb-2" v-if="!$acl.check('hospitalgroup')">
          <label class="text-sm opacity-75">{{ $t("Patients") }}</label>
          <v-select
            label="Name"
            class="w-full"
            v-model="SearchObj.PatientID"
            :options="Patients"
            :reduce="(ID) => ID.ID"
          />
        </div>
        <!-- HospitalID -->
        <div
          v-if="(SearchObj.BookingTypeID == 2 || SearchObj.BookingTypeID == 3)&&!$acl.check('hospitalgroup')"
          class="vx-col md:w-1/5 sm:w-1/2 w-full mb-2"
        >
          <label class="text-sm opacity-75">{{ $t("Hospitals") }}</label>
          <v-select
            label="Name"
            class="w-full"
            v-model="SearchObj.HospitalID"
            :options="Hospitals"
            :reduce="(ID) => ID.ID"
          />
        </div>
        <!-- StatusID -->
        <div class="vx-col md:w-1/5 sm:w-1/2 w-full mb-2">
          <label class="text-sm opacity-75">{{ $t("Status") }}</label>
          <v-select
            label="Name"
            class="w-full"
            v-model="SearchObj.StatusID"
            :options="status"
            :reduce="(ID) => ID.ID"
          />
        </div>
        <!-- PaymentStatusID -->
        <div class="vx-col md:w-1/5 sm:w-1/2 w-full mb-2">
          <label class="text-sm opacity-75">{{ $t("Payment Status") }}</label>
          <v-select
            label="Name"
            class="w-full"
            v-model="SearchObj.PaymentStatusID"
            :options="PaymentStatus"
            :reduce="(ID) => ID.ID"
          />
        </div>
        <!-- IsPendingPayment -->
        <div class="vx-col md:w-1/5 sm:w-1/2 w-full mb-2">
          <vs-checkbox
            class="mt-1 w-full justify-left"
            v-model="SearchObj.IsPendingPayment"
            >{{ $t("Pending Payment") }}</vs-checkbox
          >
        </div>
        <!-- IsPendingDocument -->
        <div class="vx-col md:w-1/5 sm:w-1/2 w-full mb-2">
          <vs-checkbox
            class="mt-1 w-full justify-left"
            v-model="SearchObj.IsPendingDocument"
            >{{ $t("Pending Document") }}</vs-checkbox
          >
        </div>

        <div class="vx-col w-full">
          <div class="vx-row">
            <vs-button
              style="margin: 10px"
              class="my-3"
              @click="getdata"
              v-scroll-to="'#Scroll'"
              >{{ $t("Search") }}</vs-button
            >
            <vs-button style="margin-left: 10px" class="my-3" @click="resetData" >{{
              $t("Reset")
            }}</vs-button>
          </div>
        </div>
      </div>
    </vx-card>

    <vs-table
      id="Scroll"
      ref="table"
      multiple
      v-model="selected"
      pagination
      :max-items="itemsPerPage"
      search
      :data="Books"
    >
      <div
        slot="header"
        class="flex flex-wrap-reverse items-center flex-grow justify-between"
      >
        <!-- ITEMS PER PAGE -->
        <vs-dropdown vs-trigger-click class="cursor-pointer mb-4 mr-4">
          <div
            class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
          >
            <span class="mr-2"
              >{{ currentPage * itemsPerPage - (itemsPerPage - 1) }} -
              {{
                Books.length - currentPage * itemsPerPage > 0
                  ? currentPage * itemsPerPage
                  : Books.length
              }}
              of {{ Books.length }}</span
            >
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>
          <vs-dropdown-menu>
            <vs-dropdown-item @click="itemsPerPage = 4">
              <span>4</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage = 10">
              <span>10</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage = 15">
              <span>15</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage = 20">
              <span>20</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
      </div>

      <template slot="thead">
        <vs-th sort-key="Doctor">{{ $t("Reference Number") }}</vs-th>
        <vs-th sort-key="Service type">{{ $t("Service type") }}</vs-th>
        <vs-th sort-key="Date of service">{{ $t("Date of service") }}</vs-th>
        <vs-th sort-key="Status">{{ $t("Status") }}</vs-th>
        <vs-th sort-key="Payment Status">{{ $t("Payment Status") }}</vs-th>
        <vs-th sort-key="Patient">{{ $t("Patient") }}</vs-th>
        <vs-th sort-key="hospital/doctor">{{ $t("Hospital/Doctor") }}</vs-th>
        <vs-th>{{ $t("Action") }}</vs-th>
      </template>

      <template slot-scope="{ data }">
        <tbody>
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td>
              <p class="product-name font-medium truncate">
                {{ tr.RefNumber }}
              </p>
            </vs-td>
            <vs-td>
              <p class="product-name font-medium truncate">
                {{ tr.BookingTypeName }}
              </p>
            </vs-td>

            <vs-td>
              <p class="product-name font-medium truncate">
                <!-- {{ tr.DoctorSession.DayUTC.split("T")[0] }} -->
                {{ tr.BookingDate | formatDate() }}
                <!-- {{ tr.BookingDate.slice(11, 16) }} -->
              </p>
            </vs-td>
            <vs-td>
              <p class="product-name font-medium truncate">
                {{ tr.StatusName }}
              </p>
            </vs-td>
            <vs-td>
              <p
                class="product-name font-medium truncate"
                v-if="tr.BookingTypeID != 1 && tr.PaymentStatusName == 'Pending'"
              >
                {{ $t("Pending Processing Fees") }}
              </p>
              <p class="product-name font-medium truncate" v-else>
                {{ tr.PaymentStatusName }}
              </p>
            </vs-td>
            <vs-td>
              <p class="product-name font-medium truncate">
                {{ tr.PatientName }}
              </p>
            </vs-td>
            <vs-td>
              <p class="product-name font-medium truncate">
                {{ tr.DoctorOrHospitalName }}
              </p>
            </vs-td>
            <vs-td class="whitespace-no-wrap">
              <div class="vx-row">
                <!-- edit -->
                <div class="vx-col lg:w-1/10">
                  <router-link
                    v-if="tr.BookingTypeID == 1"
                    :to="{
                      name: 'ReservationSession-Add-Edit',
                      params: { ID: tr.PatientReservationSessionID },
                    }"
                    svgClasses="w-5 h-5 hover:text-primary stroke-current"
                    class="vx-col"
                  >
                    <vx-tooltip :text="$t('Edit')" position="top" style="display: inline">
                      <feather-icon
                        icon="EditIcon"
                        svgClasses="w-6 h-6 hover:text-danger stroke-current"
                        class="ml-2 vx-col"
                      />
                    </vx-tooltip>
                  </router-link>
                  <router-link
                    v-else
                    :to="{
                      name: 'ReservationSurgery-Details',
                      params: { ID: tr.PatientReservationSurgeryID },
                    }"
                    svgClasses="w-5 h-5 hover:text-primary stroke-current"
                  >
                    <vx-tooltip :text="$t('Edit')" position="top" style="display: inline">
                      <feather-icon
                        icon="EditIcon"
                        svgClasses="w-6 h-6 hover:text-danger stroke-current"
                        class="ml-2"
                      />
                    </vx-tooltip>
                  </router-link>
                </div>
                <!-- re appointment -->
                <div class="vx-col lg:w-1/10">
                  <div v-if="tr.BookingTypeID == 1">
                    <vx-tooltip
                      :text="$t('Edit Session Date')"
                      position="top"
                      style="display: inline"
                    >
                      <feather-icon
                        v-if="tr.StatusID != 3 && tr.StatusID != 5"
                        icon="RefreshCcwIcon"
                        svgClasses="w-6 h-6 hover:text-danger stroke-current"
                        class="ml-2"
                        @click="OpentReAppointmentPopup(tr)"
                      />
                    </vx-tooltip>
                  </div>
                </div>
                <!-- Cancel -->
                <div v-if="tr.StatusID != 3" class="vx-col lg:w-1/10">
                  <div v-if="tr.BookingTypeID == 1">
                    <vx-tooltip
                      :text="$t('cancel')"
                      position="top"
                      style="display: inline"
                    >
                      <feather-icon
                        @click.stop="
                          CancelModel = tr;
                          openConfirm();
                        "
                        icon="XCircleIcon"
                        svgClasses=" h-6 hover:text-danger stroke-current"
                        class="ml-2 vx-col"
                      />
                    </vx-tooltip>
                  </div>
                  <div v-else svgClasses="w-5 h-5 hover:text-primary stroke-current">
                    <vx-tooltip
                      :text="$t('cancel')"
                      position="top"
                      style="display: inline"
                    >
                      <feather-icon
                        @click.stop="
                          CancelModel = tr;
                          openConfirm();
                        "
                        icon="XCircleIcon"
                        svgClasses="w-6 h-6 hover:text-danger stroke-current"
                        class="ml-2 vx-col"
                      />
                    </vx-tooltip>
                  </div>
                </div>
                <div class="vx-col lg:w-7/10"></div>
              </div>
            </vs-td>
          </vs-tr>
        </tbody>
      </template>
    </vs-table>
    <vs-popup title="" :active.sync="showEditSessionDialog">
      <EditSessionAppointmet
        v-if="showEditSessionDialog"
        @closePop="showEditSessionDialog = false"
        :editSessionModel="reAppModel"
      ></EditSessionAppointmet>
    </vs-popup>
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import modulePatient from "@/store/Patient/modulePatient.js";
import moduleDoctor from "@/store/doctor/moduleDoctor.js";
import moduleHospital from "@/store/Hospital/moduleHospital.js";
import moduleStatus from "@/store/settings/status/moduleStatus.js";
import modulePaymentStatus from "@/store/settings/paymentStatus/modulePaymentStatus.js";
import moduleReservation from "@/store/reservation/ReservationSurgery/moduleReservation.js";
import moduleAppointment from "@/store/reservation/ReservationSession/moduleReservation.js";
import EditSessionAppointmet from "@/views/reservation/ReservationSession/EditSessionAppointmet";
export default {
  components: {
    Datepicker,
    EditSessionAppointmet,
  },
  data() {
    return {
      selected: [],
      Books: [],
      BookingTypeList: [
        { label: "Appointment", value: 1 },
        { label: "Surgery", value: 2 },
        { label: "Both", value: 3 },
      ],
      search: {
        BookingTypeID: 1, //appointment
      },
      itemsPerPage: 10,
      isMounted: false,
      CancelModel: [],
      reAppModel: {},
      activeConfirm: false,
      showEditSessionDialog: false,
    };
  },
  computed: {
    SearchObj() {
      debugger;
      return this.$store.state.ReservationSurgeryList.SearchObj;
    },

    currentPage() {
      if (this.isMounted) {
        return this.$refs.table.currentx;
      }
      return 0;
    },
    status() {
      return this.$store.state.StatusList.status;
    },
    doctors() {
      return this.$store.state.DoctorList.doctors;
    },
    Patients() {
      return this.$store.state.patientList.patients;
    },
    Hospitals() {
      return this.$store.state.HospitalList.Hospitals;
    },
    PaymentStatus() {
      debugger;
      if (this.$store.state.PaymentStatusList.PaymentStatus.length > 0) {
        for (
          var counter = 0;
          counter < this.$store.state.PaymentStatusList.PaymentStatus.length;
          counter++
        ) {
          debugger;
          if (
            this.$store.state.PaymentStatusList.PaymentStatus[counter].ID == 1 &&
            this.$store.state.PaymentStatusList.PaymentStatus[counter].Name == "Pending"
          ) {
            debugger;
            this.$store.state.PaymentStatusList.PaymentStatus[counter].Name =
              "Pending / Pending Processing Fees";
          }
        }
      }
      debugger;
      return this.$store.state.PaymentStatusList.PaymentStatus;
    },
    status() {
      return this.$store.state.StatusList.status;
    },
  },

  methods: {
    doctorSessionSearch() {
      debugger;
      const ID = this.$route.params.ID;
      if (ID > 0) {
        this.search.DoctorID = ID;
      } else this.search.DoctorID = null;

      debugger;
      this.$store.dispatch(
        "ReservationList/SearchPatientReservationSessions",
        this.search
      );
    },
    OpentReAppointmentPopup(session) {
      debugger;
      var dayNow = new Date();
      var myDate = new Date(session.DoctorSession.DayUTC);
      debugger;
      dayNow = new Date(dayNow.getTime() + 3 * 24 * 60 * 60 * 1000);
      var date1 = dayNow.getTime();
      var date2 = myDate.getTime();
      if (date1 > date2) {
        this.$vs.notify({
          color: "warning",
          title: "Can't reappointment",
          text: this.$t(
            "can't edit reservation before 72h , please cancel it and rebooking"
          ),
        });
      } else {
        debugger;
        this.reAppModel = session;
        this.showEditSessionDialog = true;
      }
    },
    CancelReservation() {
      debugger;
      if (this.CancelModel.PatientReservationSessionID) {
        this.cancelSession(this.CancelModel);
      }
      if (this.CancelModel.PatientReservationSurgeryID != null) {
        this.cancelSurgery(PatientReservationSurgery);
      }
    },
    cancelSession(PatientReservationSession) {
      debugger;
      let m = {};
      m.PatientReservationSessionID =
        PatientReservationSession.PatientReservationSessionID;
      m.PatientID = PatientReservationSession.PatientID;
      m.DoctorSessionID = PatientReservationSession.DoctorSessionID;
      m.DoctorID = PatientReservationSession.DoctorID;
      m.StatusID = 3;

      this.$store
        .dispatch("ReservationList/ChangePatientReservationSessionStatus", m)
        .then(() => {
          this.$vs.notify({
            color: "success",
            title: "Cancel reservation",
            text: this.$t("the cancellation done succssfuly"),
          });

          this.getdata();
          this.initValues();
          this.Back();
          this.$vs.loading.close();
        })
        .catch((err) => {
          if (err.response.status == 400) {
            window.showAddFailed(err.response.data.message);
          } else {
            window.showAddFailed("");
          }
          //console.error(err);
          this.$vs.loading.close();
        });
    },
    resetData() {
      this.SearchObj = {};
      if(this.$acl.check('hospitalgroup'))
         this.$set(this.SearchObj, "BookingTypeID", 2);
      else
         this.$set(this.SearchObj, "BookingTypeID", 1);
        
      this.$set(this.SearchObj, "HospitalID", null);
      this.$set(this.SearchObj, "PatientID", null);
      this.$set(this.SearchObj, "DoctorID", null);
      this.$set(this.SearchObj, "PaymentStatusID", null);
      this.$set(this.SearchObj, "StatusID", null);

      //  this.search.BookingTypeID=3;
      this.SearchObj.DateFrom =
        new Date().getFullYear() + "-" + (new Date().getMonth() + 1) + "-1";

      this.SearchObj.DateTo =
        new Date().getFullYear() +
        "-" +
        (new Date().getMonth() + 1) +
        "-" +
        new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).getDate();
      this.SearchObj.IsPendingPayment = null;
      this.SearchObj.IsPendingDocument = null;
      this.getdata();
    },
    getdata() {
      if (this.SearchObj.IsPendingDocument == false)
        this.SearchObj.IsPendingDocument = null;
      if (this.SearchObj.IsPendingPayment == false)
        this.SearchObj.IsPendingPayment = null;
      this.Books = [];
      this.$vs.loading();
      debugger;
    
      if(this.$acl.check('hospitalgroup'))
      {
          this.SearchObj.HospitalGroupID = this.$store.state.AppActiveUser.HospitalGroupID;
      }

      this.$store
        .dispatch("patientList/SearchGeneralBooking", this.SearchObj)
        .then((res) => {
          debugger;
          this.Books = res.data.Data;
          debugger;
          // this.$store.dispatch("ReservationList/setSearchObject", this.SearchObj)
          debugger;
          this.$vs.loading.close();
        })
        .catch(() => {
          this.$vs.loading.close();
        });
    },
    openConfirm() {
      debugger;
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: `Cancel`,
        text: this.$t("Do you want Cancle this reservation ?"),
        acceptText: this.$t("Cancel"),
        cancelText: this.$t("No"),
        accept: this.acceptAlert,
      });
    },

    acceptAlert() {
      this.CancelReservation();
    },
  },

  created() {
    if (!moduleStatus.isRegistered) {
      this.$store.registerModule("StatusList", moduleStatus);
      moduleStatus.isRegistered = true;
    }
    this.$store.dispatch("StatusList/GetAllStatus");

    if (!modulePaymentStatus.isRegistered) {
      this.$store.registerModule("PaymentStatusList", modulePaymentStatus);
      modulePaymentStatus.isRegistered = true;
    }
    this.$store.dispatch("PaymentStatusList/GetAllPaymentStatus");

    if (!moduleDoctor.isRegistered) {
      this.$store.registerModule("DoctorList", moduleDoctor);
      moduleDoctor.isRegistered = true;
    }
    if (!moduleAppointment.isRegistered) {
      this.$store.registerModule("ReservationList", moduleAppointment);
      moduleAppointment.isRegistered = true;
    }
    this.$store.dispatch("DoctorList/GetAllDoctorsList");

    if (!modulePatient.isRegistered) {
      this.$store.registerModule("patientList", modulePatient);
      modulePatient.isRegistered = true;
    }
    this.$store.dispatch("patientList/SearchPatients", {});

    if (!moduleHospital.isRegistered) {
      this.$store.registerModule("HospitalList", moduleHospital);
      moduleHospital.isRegistered = true;
    }
    this.$store.dispatch("HospitalList/GetAllHospitals", {});
    if (!moduleReservation.isRegistered) {
      this.$store.registerModule("ReservationSurgeryList", moduleReservation);
      moduleReservation.isRegistered = true;
    }

    if(this.$acl.check('hospitalgroup'))
    {
       this.SearchObj.BookingTypeID = 2;
    }
    //this.SearchObj
    // this.resetData();
    this.getdata();
  },
  mounted() {
    this.isMounted = true;
  },
};
</script>

<style lang="scss">
#data-list-list-view {
  .vs-con-table {
    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap-reverse;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
        td {
          padding: 20px;
          &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }
          &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }
        }
        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }
      th.td-check {
        padding: 0 15px !important;
      }
      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>
<style>
.ulText {
  list-style-type: square;
  margin: 0 !important;
  padding: 2rem;
  text-align: left !important;
}
</style>
